import { Collapse, CollapseProps, theme } from 'antd';
import styles from './index.module.less';
import { CSSProperties } from 'react';
import { CommonBackContent } from '@/components/Layouts/CommonBackContent';

const FAQContent = ({ data, pageContent }: { data?: any; pageContent?: any }) => {
  const { token } = theme.useToken();

  const getItems: (panelStyle: CSSProperties) => CollapseProps['items'] = (panelStyle) => {
    return pageContent?.faqList?.content_list?.map((item: any, index: number) => {
      return {
        label: (
          <h3 style={{ color: '#fff', fontSize: 20, fontFamily: 'Inter-Regular' }}>
            {item.question}
          </h3>
        ),
        children: (
          <div className={styles.infoContainer}>
            <p key={item.question + index} dangerouslySetInnerHTML={{ __html: item.answer }} />
          </div>
        ),
        key: `${index}`,
        style: panelStyle,
      };
    });
  };

  const panelStyle: React.CSSProperties = {
    marginBottom: 24,
    background: `var(--ground-rgb-1)`,
    borderRadius: token.borderRadiusLG,
    border: 'none',
    color: 'red',
    boxShadow: `0 0 2px #D9D9D9`,
  };

  return (
    <CommonBackContent style={{ padding: 0 }}>
      <div className={styles.content}>
        <div className={styles.topContainer}>
          <h2>{pageContent?.contentInfo?.['section-faq']?.title}</h2>
        </div>

        <Collapse
          className={styles.collapseContent}
          bordered={false}
          items={getItems(panelStyle)}
          defaultActiveKey={undefined}
        />
      </div>
    </CommonBackContent>
  );
};

export default FAQContent;
